import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import {
  ContactNumber,
  ContactNumbersResponse,
} from '@shared/model/system-settings/contact-number-response.model';
import { DropDownResponse } from '@shared/model/system-settings/drop-down-response.model';
import { DropDownType } from '@shared/enums/drop-down-type.enum';
import { map, Observable, of, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SystemSettingsConfig } from '../model/system-settings/system-settings-config.model';

@Injectable({
  providedIn: 'root',
})
export class SystemSettingsService {
  private readonly baseUrl = `${environment.apiUrl}/v2/SystemSettings`;
  private httpClient = inject(HttpClient);
  private _systemConfig: SystemSettingsConfig | null = null;

  getSystemConfig() {
    if (this._systemConfig) {
      return of(this._systemConfig);
    }
    return this.httpClient
      .get<SystemSettingsConfig>(`${this.baseUrl}/Info/Config`)
      .pipe(
        tap((config) => {
          this._systemConfig = config;
        })
      );
  }

  getContactNumbers(): Observable<ContactNumber[]> {
    return this.httpClient
      .get<ContactNumbersResponse>(`${this.baseUrl}/Info/ContactNumbers`)
      .pipe(map((data: ContactNumbersResponse) => data.contactNumbers));
  }

  getDropDownListByType<T = string>(
    type: DropDownType,
    config?: {
      headers?: { key: string; value: string }[];
      operationAreas?: string[];
      serviceAndRequestType?: string;
    }
  ) {
    let headers = new HttpHeaders();
    if (config?.headers?.length) {
      config?.headers.forEach(
        (configHeader) =>
          (headers = headers.set(configHeader.key, configHeader.value))
      );
    }
    let params = new HttpParams();
    if (config?.operationAreas?.length) {
      config?.operationAreas.forEach(
        (operationArea) =>
          (params = params.append('operationAreas', operationArea))
      );
    }
    if (config?.serviceAndRequestType) {
      params = params.append(
        'serviceAndRequestType',
        config.serviceAndRequestType
      );
    }
    return this.httpClient.get<DropDownResponse<T>>(
      `${this.baseUrl}/DropDowns/DropDownItems/${type}`,
      {
        headers,
        params,
      }
    );
  }
}
